import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, provider } from '../../config/Firebase';
import { signInWithPopup } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserId } from '../../features/LoginSlice';
import './loginregister.css';
import olx from './images/OLX-logo.png'
import google from './images/google.png'

export default function LoginFirebase() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            alert('Login Successful');
            console.log(user.email, 'email loggedIn');
            console.log(user.uid, 'User UID loggedIn');


            dispatch(setUserId(user.uid));


            navigate('/private/homepage');
        } catch (error) {
            console.error('Login failed:', error.message);
            alert('Login failed: ' + error.message);
        }
    };

    //signin with google

    const [value, setValue] = useState('')

    const handleGoogle = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                const userEmail = data.user.email;
                setValue(userEmail);
                localStorage.setItem('email', userEmail);
                dispatch(setUserId(data.user.uid));
                navigate('/private/homepage');
            })
            .catch((error) => {
                console.error('Google Sign-In failed:', error.message);
                alert('Google Sign-In failed: ' + error.message);
            });
    };

    useEffect(() => {
        setValue(localStorage.getItem('email'));
    }, []);

    return (
        <div className='bodyy'>
            <div>
                <br /><br /><br /><br /><br />
                <div className='data-login-register'>
                    <img src={olx} className='olx-logo-reg-log ' />
                    <form onSubmit={handleLogin}>
                        <h6 className='mt-3 mb-3 fw-bold'>Login into your OLX account</h6>
                        <div >
                            <input
                                type='email'
                                className='email'
                                placeholder='Email'
                                required
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <br />
                            <br />
                            <input
                                type='password'
                                className='password'
                                placeholder='Password'
                                required
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <br />
                            <br />
                            <button type='submit' className='buttonnnnn'>Login</button>
                            <div className='mt-3'>
                                <span className='new-old-olx '>
                                 Create an new Account ?
                                    &nbsp;
                                    <Link to='/signup'>
                                        Signup
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </form>
                    <div className="separatorrr">OR</div>

                    <button type="button" className="sociial-button googgle-button" onClick={handleGoogle}>
                        <img src={google} alt="Google Icon" /> Signin with Google
                    </button>


                </div>



            </div>
        </div>
    );
}


