import React from 'react';
import Login from './components/Login Screen/LoginFirebase';



const Main = () => {
  

  return (

<>
<Login/>
</>

);
};

export default Main;
