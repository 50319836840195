import { initializeApp } from 'firebase/app';
import { getAuth , GoogleAuthProvider} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { Firestore, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC83OKTly7f8moj7oP6vdszR52SBQodHK8",
  authDomain: "olx-165ae.firebaseapp.com",
  databaseURL: "https://olx-165ae-default-rtdb.firebaseio.com",
  projectId: "olx-165ae",
  storageBucket: "olx-165ae.appspot.com",
  messagingSenderId: "58798440513",
  appId: "1:58798440513:web:1b31804a7a8662a57cc6c0",
  measurementId: "G-MPDQ8STHZT"
};


const app = initializeApp(firebaseConfig);


const auth = getAuth(app);
const provider = new GoogleAuthProvider()
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, provider ,storage };
export default firebaseConfig;
