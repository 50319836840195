import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, db, provider } from '../../config/Firebase';
import { setDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserId } from '../../features/LoginSlice';
import { useNavigate } from 'react-router-dom';
import './loginregister.css';
import olx from './images/OLX-logo.png'
import google from './images/google.png'

export default function SignupFirebase() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            console.log(user, 'user-fileloginfirebase-line13');

            if (user) {
                await setDoc(doc(db, 'Users', user.uid), {
                    email: user.email,
                });


                dispatch(setUserId(user.uid));

                alert('User Registered Successfully!');
                console.log(user.uid, 'user uId');
            }
        } catch (error) {
            console.log(error.message);
            alert(error.message);
        }
    };


    //signup with google

    const [value, setValue] = useState('')

    const signupGoogle = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                const userEmail = data.user.email;
                setValue(userEmail);
                localStorage.setItem('email', userEmail);
                dispatch(setUserId(data.user.uid));
                navigate('/private/homepage');
            }
            )
            .catch((error) => {
                console.error('Google Sign-In failed:', error.message);
                alert('Google Sign-In failed: ' + error.message);
            });
    };

    useEffect(() => {
        setValue(localStorage.getItem('email'));
    }, []);


    return (
        <>

            <div className='bodyy'>
                <div>
                    <br /><br /><br /><br /><br />
                    <div className='data-login-register '>
                        <img src={olx} className='olx-logo-reg-log ' />
                        <form onSubmit={handleRegister}>
                            <h6 className='mt-3 mb-3 fw-bold'> Create a new OLX account</h6>
                            <div>
                                <input
                                    type='email'
                                    className='email'
                                    placeholder='Email'
                                    required
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                <br />
                                <br />
                                <input
                                    type='password'
                                    className='password'
                                    placeholder='Password'
                                    required
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <br />
                                <br />
                                <button type='submit' className='buttonnnnn'>Signup</button>
                                <div className='mt-3'>
                                    <span className='new-old-olxx '>
                                        Already have an account ?
                                        &nbsp;
                                        <Link to='/login'>
                                            Login
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </form>
                        <div className="separatorrr">OR</div>
                        <button type="button" className="sociial-button googgle-button" onClick={signupGoogle} >
                            <img src={google} alt="Google Icon" /> Signup with Google
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
