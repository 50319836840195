// import { createSlice } from "@reduxjs/toolkit";

// const LoginSlice = createSlice({
//   name: 'login',
//   initialState: {
//     olxusers: [],  
//     currentUser: null,
//   },
//   reducers: {
//     signup: (state, action) => {
//       const { Email } = action.payload;

//       if (!Array.isArray(state.olxusers)) {
//         state.olxusers = [];
//       }

//       const userExists = state.olxusers.some(user => user.Email === Email);

//       if (!userExists) {
//         state.olxusers.push(action.payload);  
//       } else {
//         alert('User already exists. Please log in.');
//       }
//     },
//     loginuser: (state, action) => {
//       const { Email, Password } = action.payload;
//       const user = state.olxusers.find(
//         user => user.Email === Email && user.Password === Password
//       );

//       if (user) {
//         state.currentUser = user;
//       } else {
//         alert('Invalid credentials. Please try again.');
//       }
//     },
//     logout: (state) => {
//       state.currentUser = null;

//     },
//   },
// });

// export const { signup, loginuser, logout } = LoginSlice.actions;
// export default LoginSlice.reducer;

//redux end


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: [],  
    currentId: null 
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        
        setUserId: (state, action) => {
            if (!state.id) {
                state.id = []; 
            }
            state.currentId = action.payload; 
            if (!state.id.includes(action.payload)) {
                state.id.push(action.payload); 
            }
        },
        
        clearUserId: (state) => {
            state.currentId = null;  
        },
        
        addUserId: (state, action) => {
            if (!state.id) {
                state.id = []; 
            }
            if (!state.id.includes(action.payload)) {
                state.id.push(action.payload); 
            }
        },

        removeUserId: (state, action) => {
            state.id = state.id.filter(id => id !== action.payload);
        }
    }
});


export const { setUserId, clearUserId, addUserId, removeUserId } = userSlice.actions;


export default userSlice.reducer;
